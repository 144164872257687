import { Notify } from 'notiflix';
import { Role } from '../constants/role.enum';
import axios from 'axios';
import notifyOptions from '../constants/notify.options';

const refreshInterval = process.env.REACT_APP_TOKEN_REFRESH_INTERVAL || 3600000;
/**
 * @TODO: Fix issue with Cadenza dec
 * Hardcoded due to a problem with the .env environment in dev Cadenza
 * */
const baseURL = process.env.REACT_APP_BASE_URL || 'https://mycdzcloud-nodeapp-dev.azurewebsites.net/api';

// Save the current access token lifetime in local storage
export const saveAccessTokenLifetime = () => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const accessLifetime = (currentTimestamp + refreshInterval / 1000).toString();
  localStorage.setItem('accessLifetime', accessLifetime);
};

// Check if the access token has expired
export const isAccessTokenExpired = () => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const accessLifetime = parseInt(localStorage.getItem('accessLifetime'));

  return currentTimestamp >= accessLifetime;
};

// Refresh the access token using the refresh token
export const refreshingTokens = async () => {
  try {
    const refreshToken = localStorage.getItem('cdnzRefreshToken');
    const userRole = localStorage.getItem('cdnzRole');
    const isAdmin = [
      Role.PLATFORM_ADMINISTRATOR,
      Role.CLIENT_ADMINISTRATOR,
    ].includes(userRole);

    const response = await axios.post(
      `${baseURL}/authentication/refresh-token`,
      {
        refreshToken,
        isAdmin,
      }
    );

    // Save the new access and refresh tokens to local storage
    localStorage.setItem('cdnzAccessToken', response.data.tokens.accessToken);
    localStorage.setItem('cdnzRefreshToken', response.data.tokens.refreshToken);
    saveAccessTokenLifetime();
  } catch (error) {
    Notify.failure('Error refreshing token.', notifyOptions);
    localStorage.clear();
    return (window.location.href = '/login');
  }
};
