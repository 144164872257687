import axios from 'axios';

/**
 * @TODO: Fix issue with Cadenza dec
 * Hardcoded due to a problem with the .env environment in dev Cadenza
 * */
export const baseURL = process.env.REACT_APP_BASE_URL || 'https://mycdzcloud-nodeapp-dev.azurewebsites.net/api';
export const axiosBaseUrl = axios.create({ baseURL });

export const token = {
  set(token) {
    axiosBaseUrl.defaults.headers.common['Authorization'] = token
      ? `Bearer ${token}`
      : '';
  },
  unset() {
    this.set();
  },
};
