import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export const getAssetData = async (assetName) => {
  try {
    const response = await api.get(
      `/device/asset-data/${assetName}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    return response.data.assetData;
  } catch (error) {
    Notify.warning(`Asset ${assetName} data not loaded. Please try later.`, notifyOptions);
    throw error;
  }
};

export const updateAssetData = async (updatedData) => {
  try {
    const response = await api.put(
      `/device/asset-data`,
      { ...updatedData },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    if (response.data.status === 'success') {
      Notify.success('Asset details updated successfully!', notifyOptions);
    };
  } catch (error) {
    Notify.warning(`Asset data not updated. Please try later.`, notifyOptions);
    throw error;
  }
};

export const getUpsAssetData = async (assetName) => {
  try {
    const response = await api.post(
      '/device/ups-data',
      {
        assetName
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username,
        },
      }
    );

    return response.data.upsData;
  } catch (error) {
    Notify.warning('UPS data are not loaded. Please try later.', notifyOptions);
    throw error;
  }
};