// React
import React, { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

// Services
import {
  fetchAccountEnergyData as fetchAccountEnergyDataService,
  fetchAccountPowerData as fetchAccountPowerDataService,
} from '../../services/graphService';

// Helpers
import { idToText } from '../../helpers/widget/idToText';
import { calculateTimeRangeUtc, calculateDateRangeUtc } from '../../helpers/formatDate/calculateTimeRange';

// Utils
import prepLayout from '../../utils/PrepLayout';

// Components
import DashboardSelect from '../modals/DashboardSelect';
import EditAssetDetails from '../modals/EditAssetDetails';
import Modal from '../modals/Modal';
import HomeTopBar from '../pageComponents/home/HomeTopBar';
import BessDashboard from '../modals/BessDashboard';
import UpsDashboard from '../modals/UpsDashboard';
import Loader from '../Loader';

// Widget Components
import Widget from '../widgets/Widget';
import AssetList from '../widgets/AssetList';
import VppList from '../widgets/VppList';
import Power from '../widgets/Power';
import FleetStatus from '../widgets/FleetStatus';
import Energy from '../widgets/Energy';
import Trees from '../widgets/TreesSaved';
import ScheduledEvents from '../widgets/ScheduledEvents';
import AssetLocations from '../widgets/AssetLocations';
import AssetListFaults from '../widgets/AssetListFaults';
import AssetListUnassigned from '../widgets/AssetListUnassigned';
import VppDashboard from '../modals/VppDashboard';

const HomeContainer = ({
  dashboardData = {},
  accountsData = [],
  getDashboardData,
}) => {
  const {
    assetList = [],
    dashboardLayout = [],
    events = [],
    treesCount,
    vppList = [],
  } = dashboardData;

  // Init responsive grid layout
  const ResponsiveGridLayout = WidthProvider(Responsive);

  // State
  const [layout, setLayout] = useState(dashboardLayout);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [addWidget, setAddWidget] = useState(false);
  const [showAssetDetails, setShowAssetDetails] = useState(false);
  const [showVppAssetDetails, setShowVppAssetDetails] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showRealTimeDataModal, setShowRealTimeDataModal] = useState(false);
  const [showUpsRealTimeDataModal, setShowUpsRealTimeDataModal] = useState(false);
  const [showVppDashboardModal, setShowVppDashboardModal] = useState(false);
  const [targetData, setTargetData] = useState();

  const [isAccountEnergyLoading, setIsAccountEnergyLoading] = useState(true);
  const [isAccountPowerLoading, setIsAccountPowerLoading] = useState(true);

  const [powerStartDate, setPowerStartDate] = useState('');
  const [powerEndDate, setPowerEndDate] = useState('');
  const [energyStartDate, setEnergyStartDate] = useState('');
  const [energyEndDate, setEnergyEndDate] = useState('');

  const [power, setPower] = useState([]);
  const [energy, setEnergy] = useState([]);

  // Width handling
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      window.scrollTo(0, scrollPosition);
    }, 0);
  };

  useEffect(() => {
    scrollToPreviousPosition();
  }, [scrollToPreviousPosition]);

  useEffect(() => {
    fetchInitialChartsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to handle scroll position change
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // Toggle show remove button on widgets
  const toggleShowRemove = () => {
    setScrollPosition(0);
    setShowRemove(!showRemove);
  };

  // Change modal states
  const toggleAddWidget = () => {
    setScrollPosition(0);
    setAddWidget(!addWidget);
  };

  // Toggle asset details modal
  const toggleAssetDetails = () => {
    setScrollPosition(0);
    setShowAssetDetails(!showAssetDetails);
  };

  // Toggle VPP asset details modal
  const toggleVppAssetDetails = () => {
    setScrollPosition(0);
    setShowVppAssetDetails(!showVppAssetDetails);
  };

  // Toggle BESS dashboard
  const toggleBessDashboard = () => {
    setScrollPosition(0);
    setShowRealTimeDataModal(!showRealTimeDataModal);
  };

  // Toggle UPS dashboard
  const toggleUpsDashboard = () => {
    setScrollPosition(0);
    setShowUpsRealTimeDataModal(!showUpsRealTimeDataModal);
  };

  // Toggle VPP dsshboard modal
  const toggleVppDashboard = () => {
    setScrollPosition(0);
    setShowVppDashboardModal(!showVppDashboardModal);
  };

  // Toggle widget hidden status
  const toggleHideWidget = id => {
    // find the item with the given id
    let item = layout.find(item => item.i === id);
    // toggle the hidden property of the item
    item.hidden = !item.hidden;
    // copy the original items array
    const newItems = [...layout];
    // update the item in the newItems array
    newItems[newItems.indexOf(item)] = item;
    // update state with the newItems array
    setLayout(newItems);
  };

  // Set asset details modal target data
  const setTargetDetails = targetItem => {
    setTargetData(targetItem);
  };

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(layouts, layout, 'homeLayout');
    }
  };

  // Fetch initial data for charts
  const fetchInitialChartsData = async () => {
    const {
      startToUTC,
      endToUTC,
      startOfDayLocalTime,
      endOfDayLocalTime,
      dayForEnergy
    } = calculateTimeRangeUtc();

    // Fetch data for account power
    try {
      const powerData = await fetchAccountPowerDataService(startToUTC, endToUTC);
      setPower(powerData);
    } catch (error) {
      console.error('Error fetching account power data:', error);
    } finally {
      setPowerStartDate(startOfDayLocalTime);
      setPowerEndDate(endOfDayLocalTime);
      setIsAccountPowerLoading(false);
    }

    // Fetch data for account energy
    try {
      const energyData = await fetchAccountEnergyDataService(dayForEnergy, dayForEnergy);
      setEnergy(energyData);
    } catch (error) {
      console.error('Error fetching account energy data:', error);
    } finally {
      setEnergyStartDate(startOfDayLocalTime);
      setEnergyEndDate(endOfDayLocalTime);
      setIsAccountEnergyLoading(false);
    }
  };

  // Date filter functionality for charts
  const dateFilter = async (element, startDateParam, endDateParam) => {
    if (!element || !startDateParam || !endDateParam) return;

    const {
      startToUTC,
      endToUTC,
      startOfDayUTC,
      endOfDayUTC,
    } = calculateDateRangeUtc(startDateParam, endDateParam);

    handleScroll();

    if (element === 'power') {
      try {
        setIsAccountPowerLoading(true);
        const powerData = await fetchAccountPowerDataService(startToUTC, endToUTC);
        setPower(powerData);
      } catch (error) {
        console.error('Error fetching account power data:', error);
      } finally {
        setPowerStartDate(startDateParam);
        setPowerEndDate(endDateParam);
        setIsAccountPowerLoading(false);
      }
    }

    if (element === 'energy') {
      try {
        setIsAccountEnergyLoading(true);
        const energyData = await fetchAccountEnergyDataService(startOfDayUTC, endOfDayUTC);
        setEnergy(energyData);
      } catch (error) {
        console.error('Error fetching account energy data:', error);
      } finally {
        setEnergyStartDate(startDateParam);
        setEnergyEndDate(endDateParam);
        setIsAccountEnergyLoading(false);
      }
    }
  };

  return (
    <main className="flex justify-center p-2 md:p-5 min-h-full">
      <section className="container">
        <HomeTopBar
          toggleModal={toggleAddWidget}
          toggleShowRemove={toggleShowRemove}
        />
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
          rowHeight={30}
          margin={[15, 15]}
          measureBeforeMount={true}
          useCSSTransforms={false}
          draggableHandle=".draggableHandle"
          draggableCancel=".dashboardNav--cancel"
          onLayoutChange={handleLayoutChange}
        >
          {layout?.map(element => {
            if (!element.hidden) {
              // Make a human-readable title
              const title = idToText(element.i);

              // Inject child components dynamically
              let componentSwitch;
              let filter;
              let widgetDateFilter;
              let widgetDateRange;
              let createEvent;

              switch (element.i) {
                case 'assetList':
                  filter = true;
                  componentSwitch = (
                    <AssetList
                      filteredAssets={assetList}
                      vppList={vppList}
                      setTargetDetails={setTargetDetails}
                      toggleAssetRealTimeData={toggleUpsDashboard}
                      toggleAssetDetails={toggleAssetDetails}
                      toggleVppDashboard={toggleVppDashboard}
                    />
                  );
                  break;
                case 'VPPList':
                  filter = true;
                  componentSwitch = (
                    <VppList
                      filteredVpps={vppList}
                      setTargetDetails={setTargetDetails}
                      toggleVppDashboard={toggleVppDashboard}
                      toggleVppAssetDetails={toggleVppAssetDetails}
                    />
                  );
                  break;
                case 'accountPower':
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: powerStartDate,
                    end: powerEndDate,
                  };
                  componentSwitch = isAccountPowerLoading ? (
                    <div className='w-full h-full flex justify-center items-center'>
                      <Loader />
                    </div>
                  ) : (
                    <Power
                      power={power}
                      powerStartDate={powerStartDate}
                      powerEndDate={powerEndDate}
                    />
                  )
                  break;
                case 'assetFaults':
                  filter = true;
                  componentSwitch = (
                    <AssetListFaults setTargetDetails={setTargetDetails} />
                  );
                  break;
                case 'fleetStatus':
                  componentSwitch = <FleetStatus assets={assetList} />;
                  break;
                case 'accountEnergy':
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: energyStartDate,
                    end: energyEndDate,
                  };
                  componentSwitch = isAccountEnergyLoading ? (
                    <div className='w-full h-full flex justify-center items-center'>
                      <Loader />
                    </div>
                  ) : (
                    <Energy
                      energy={energy}
                      energyStartDate={energyStartDate}
                      energyEndDate={energyEndDate}
                    />
                  )
                  break;
                case 'treesSaved':
                  componentSwitch = (
                    <Trees treesCount={treesCount} />
                  );
                  break;
                case 'scheduledDR': //scheduledDREvents
                  createEvent = true;
                  componentSwitch = <ScheduledEvents />;
                  break;
                case 'assetLocations':
                  componentSwitch = <AssetLocations assets={assetList} />;
                  break;
                case 'assetsUnassigned': //AssetListUnassigned
                  filter = true;
                  componentSwitch = (
                    <AssetListUnassigned setTargetDetails={setTargetDetails} />
                  );
                  break;
                default:
                  componentSwitch = null;
                  break;
              }

              return (
                <div
                  key={element.i}
                  className="container-border grid-component__container overflow-hidden"
                >
                  <Widget
                    title={title}
                    id={element.i}
                    showRemove={showRemove}
                    toggleHideWidget={toggleHideWidget}
                    hasFilter={filter}
                    dateFilter={widgetDateFilter}
                    dateRange={widgetDateRange}
                    child={componentSwitch}
                    hasCreateEvent={createEvent}
                    assets={assetList}
                    calendarEvents={events}
                    vpps={vppList}
                  />
                </div>
              );
            }

            return null;
          })}
        </ResponsiveGridLayout>

        {!!addWidget && (
          <Modal
            toggleModal={toggleAddWidget}
            isSmall={false}
            child={
              <DashboardSelect
                layout={dashboardLayout}
                events={events}
                assets={assetList}
                vpps={vppList}
                energy={energy}
                power={power}
                treesCount={treesCount}
                toggleHideWidget={toggleHideWidget}
              />
            }
          />
        )}

        {!!showAssetDetails && (
          <Modal
            toggleModal={toggleAssetDetails}
            isSmall={false}
            child={
              <EditAssetDetails
                assetData={targetData}
                accounts={accountsData}
                toggleAssetDetails={toggleAssetDetails}
                getDashboardData={getDashboardData}
              />
            }
          />
        )}

        {!!showVppAssetDetails && (
          <Modal
            toggleModal={toggleVppAssetDetails}
            isSmall={false}
            child={
              <EditAssetDetails
                assetData={targetData}
                accounts={accountsData}
                toggleAssetDetails={toggleVppAssetDetails}
                getDashboardData={getDashboardData}
                isVppAssetDetails={true}
              />
            }
          />
        )}

        {!!showRealTimeDataModal && (
          <Modal
            toggleModal={toggleBessDashboard}
            isSmall={false}
            child={
              <BessDashboard
                assetData={targetData}
                toggleAssetDetails={toggleBessDashboard}
                toggleAssetUpsDetails={toggleUpsDashboard}
              />
            }
          />
        )}

        {!!showUpsRealTimeDataModal && (
          <Modal
            toggleModal={toggleUpsDashboard}
            isSmall={false}
            child={
              <UpsDashboard
                assetData={targetData}
                toggleAssetUpsDetails={toggleUpsDashboard}
                toggleAssetDetails={toggleBessDashboard}
              />
            }
          />
        )}

        {!!showVppDashboardModal && (
          <Modal
            toggleModal={toggleVppDashboard}
            isSmall={false}
            child={
              <VppDashboard
                assetData={targetData}
                toggleVppDashboard={toggleVppDashboard}
              />
            }
          />
        )}
      </section>
    </main>
  );
};

export default HomeContainer;
