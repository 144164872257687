import React, { memo, useEffect, useState } from 'react';
import { FiFilter, FiX, FiPlus } from 'react-icons/fi';
import { PiFileCsv } from "react-icons/pi";
import { IconContext } from 'react-icons';
import { CSVLink } from "react-csv";
import formatRangeForFileNameTables from '../../helpers/formatDate/formatRangeForFileNameTables';
import { transformDataForCSVTables } from '../../helpers/formatDate/transformDataForCSVTables';
import CalendarEvents from '../controls/CalendarEvents';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { assetRealTimeDataSelectors } from '../../redux/assetRealTimeData/assetRealTimeDataSelectors';
import { setFilterChargingEvents, setFilterDischargingEvents } from '../../redux/assetRealTimeData/assetRealTimeDataSlice';

// Access
import accessControl from '../../helpers/accessControl';

// Calendar
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

// Icons
import { BsCalendarEvent } from 'react-icons/bs';

// Utils
import getAssetStatus from '../../helpers/widget/getAssetStatus';

const Widget = memo(
  ({
    className,
    calendarEvents,
    ...props
  }) => {
    let initStartDate = '';
    let initEndDate = '';

    if (!!props.dateFilter) {
      if (!!props?.dateRange?.start) initStartDate = props?.dateRange?.start;
      if (!!props?.dateRange?.end) initEndDate = props?.dateRange?.end;
    }

    const defaultStartDate = props?.dateRange?.start
      ? new Date(props.dateRange.start)
      : null;

    const defaultEndDate = props?.dateRange?.end
      ? new Date(props.dateRange.end)
      : null;

    const defaultYear = props.year
      ? props.year
      : null;

    const [startDate, setStartDate] = useState(initStartDate);
    const [endDate, setEndDate] = useState(initEndDate);
    const [startDateTime, setStartDateTime] = useState(defaultStartDate);
    const [endDateTime, setEndDateTime] = useState(defaultEndDate);
    const [year, setYear] = useState(defaultYear);

    const [showFilter, setShowFilter] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [chargingFilter, setChargingFilter] = useState('');
    const [dischargingFilter, setDischargingFilter] = useState('');
    const [filteredAssets, setFilteredAssets] = useState(props.assets);
    const [filteredVpps, setFilteredVpps] = useState(props.vpps);
    const [filteredEvents, setFilteredEvents] = useState(calendarEvents);

    const filteredChargingEvents = useSelector(assetRealTimeDataSelectors.getFilteredChargingEvents);
    const filteredDischargingEvents = useSelector(assetRealTimeDataSelectors.getFilteredDischargingEvents);

    const rangeForFileName = formatRangeForFileNameTables(new Date(startDateTime), new Date(endDateTime));
    const assetDifferenceFromUtc = props.assetDifferenceFromUtc !== undefined ? props.assetDifferenceFromUtc : 0;
    const transformedData = transformDataForCSVTables(
      props.id === 'chargingEvents' ? filteredChargingEvents : filteredDischargingEvents,
      assetDifferenceFromUtc,
      props.id === 'chargingEvents' ? 'charge' : 'discharge'
    );

    const filterAssets = query => {
      let results = [];

      if (props.id === 'assetList') {
        if (query === '') {
          results = props.assets;
        }

        results = props.assets.filter(
          item =>
            item?.name.toLowerCase().includes(query.toLowerCase()) ||
            item?.bessName.toLowerCase().includes(query.toLowerCase()) ||
            getAssetStatus(item?.errorState).toLowerCase().includes(query.toLowerCase()) ||
            item?.siteDescription.toLowerCase().includes(query.toLowerCase()) ||
            item?.accountName.toLowerCase().includes(query.toLowerCase()) ||
            `${item?.assetContactFirstName} ${item?.assetContactLastName}`.toLowerCase().includes(query.toLowerCase()) ||
            item?.modelNumber.toLowerCase().includes(query.toLowerCase()) ||
            item?.gatewaySerialNumber.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredAssets(results);
      }

      if (props.id === 'VPPList') {
        if (query === '') {
          results = props.vpps;
        }

        results = props.vpps.filter(
          item =>
            item?.name.toLowerCase().includes(query.toLowerCase()) ||
            item?.bessName.toLowerCase().includes(query.toLowerCase()) ||
            getAssetStatus(item?.errorState).toLowerCase().includes(query.toLowerCase()) ||
            item?.siteDescription.toLowerCase().includes(query.toLowerCase()) ||
            item?.accountName.toLowerCase().includes(query.toLowerCase()) ||
            `${item?.assetContactFirstName} ${item?.assetContactLastName}`.toLowerCase().includes(query.toLowerCase()) ||
            item?.modelNumber.toLowerCase().includes(query.toLowerCase()) ||
            item?.gatewaySerialNumber.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredVpps(results);
      };
    }

    const handleChange = event => {
      setQueryValue(event.target.value);
      filterAssets(event.target.value);
    };

    const clearFilter = event => {
      setQueryValue('');
      filterAssets('');
    };

    const handleStartChange = ([date]) => {
      setStartDate(date.toString());
    };

    const handleEndChange = ([date]) => {
      setEndDate(date.toString());
    };

    const handleStartDateTimeChange = ([date]) => {
      setStartDateTime(date.toString());
    };

    const handleEndDateTimeChange = ([date]) => {
      setEndDateTime(date.toString());
    };

    useEffect(() => {
      if (
        (!!startDate && !!endDate && startDate !== initStartDate) ||
        endDate !== initEndDate
      ) {
        let filterTarget;

        if (props.id === 'BESSGridPower' || props.id === 'VPPGridPower') {
          filterTarget = 'gridPower';
        }

        if (props.id === 'batteryVoltage/Current') {
          filterTarget = 'batteryVoltage';
        }

        if (props.id === 'accountEnergy') {
          filterTarget = 'energy';
        }

        if (props.id === 'accountPower') {
          filterTarget = 'power';
        }

        if (props.id === 'BESSPerformance' || props.id === 'VPPPerformance') {
          filterTarget = 'peakShaving';
        }

        if (props.id === 'backupLoad') {
          filterTarget = 'backupLoad';
        }

        if (!!filterTarget) {
          props.dateFilter(filterTarget, startDate, endDate);
        }
      }
      // NOTE: check deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
      if (
        (!!year && year !== defaultYear)
      ) {
        let filterTarget;

        if (props.id === 'maxPower') {
          filterTarget = 'maxPower';
        }

        if (!!filterTarget) {
          props.yearFilter(filterTarget, year);
        }
      }
      // NOTE: check deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    const handleCalendarClose = () => {
      if (
        (!!startDateTime && !!endDateTime && startDateTime !== defaultStartDate) ||
        endDateTime !== defaultEndDate
      ) {
        if (props.id === 'chargingEvents') {
          props.dateTimeFilter('chargingEvents', startDateTime, endDateTime);
        }
        if (props.id === 'dischargingEvents') {
          props.dateTimeFilter('dischargingEvents', startDateTime, endDateTime);
        }
      }
    };

    const dispatch = useDispatch();

    const handleChargingFilterChange = (event) => {
      const newValue = event.target.value;
      setChargingFilter(newValue)
      dispatch(setFilterChargingEvents(newValue));
    };

    const handleDischargingFilterChange = (event) => {
      const newValue = event.target.value;
      setDischargingFilter(newValue)
      dispatch(setFilterDischargingEvents(newValue));
    };

    const handleYearChange = (event) => {
      setYear(event.target.value);
    };

    const years = Array.from(
      { length: defaultYear - 2023 },
      (_, index) => 2024 + index
    );

    return (
      <article className="grid-component__content pb-6 md:pb-8 rounded-b-lg flex flex-col h-full relative">
        {!!props.addButton && (
          <div
            className="dashboardNav--cancel add-target absolute top-0 right-0 w-full h-full flex items-center justify-center z-50 cursor-pointer"
            onClick={() => props.toggleHideWidget(props.id)}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiPlus size={62} />
            </IconContext.Provider>
          </div>
        )}
        <div className="draggableHandle px-4 pt-3 mb-0 md:pt-6 md:px-8 md:mb-2 hover:bg-slate-300 cursor-grab">
          <h1
            className={`font-medium text-xl mb-2 md:mb-4 flex items-center ${props.centerTitle ? 'justify-center' : 'justify-between'
              }`}
          >
            {props.title}
            <span>
              {!!props.hasFilter && !props.showRemove && (
                <div className="relative">
                  <button
                    className="dashboardNav--cancel p-2"
                    onClick={event => {
                      event.preventDefault();
                      setShowFilter(!showFilter);
                    }}
                  >
                    <IconContext.Provider value={{ color: '#0F3052' }}>
                      <FiFilter />
                    </IconContext.Provider>
                  </button>
                </div>
              )}
              {!!props.showRemove && (
                <button
                  className="dashboardNav--cancel p-2"
                  onClick={() => {
                    props.toggleHideWidget(props.id);
                  }}
                >
                  <IconContext.Provider value={{ color: '#0F3052' }}>
                    <FiX />
                  </IconContext.Provider>
                </button>
              )}
              {!!props.dateFilter && !props.showRemove && (
                <form className="dashboardNav--cancel py-2 flex flex-row justify-end items-center text-base">
                  <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap">
                    <div className=" flex items-center justify-between md:justify-center">
                      <p className="mr-1">Start:</p>
                      <div className=" flex items-center justify-around w-36 h-10 relative cursor-pointer">
                        <Flatpickr
                          name={`${props.id}-start-chart`}
                          placeholder="mm.dd.yyyy"
                          className="calendar-input"
                          onChange={handleStartChange}
                          options={{
                            defaultDate: defaultStartDate,
                            dateFormat: 'm.d.Y',
                            enableTime: false,
                            maxDate: defaultEndDate,
                            disableMobile: true,
                          }}
                        />
                        <BsCalendarEvent className="custom-icon absolute right-4 z-[-10]" />
                        <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                      </div>
                    </div>

                    <div className="flex items-center justify-between md:justify-center">
                      <p className="mr-1">End:</p>

                      <div className=" flex items-center justify-around w-36 h-10 relative cursor-pointer">
                        <Flatpickr
                          placeholder="mm.dd.yyyy"
                          className="calendar-input"
                          name={`${props.id}-end-chart`}
                          onChange={handleEndChange}
                          options={{
                            defaultDate: defaultEndDate,
                            dateFormat: 'm.d.Y',
                            enableTime: false,
                            minDate: defaultStartDate,
                            maxDate: props.assetTimeEndOfToday ? props.assetTimeEndOfToday : new Date().setHours(23, 59, 59, 999),
                            disableMobile: true,
                          }}
                        />
                        <BsCalendarEvent className="custom-icon absolute right-4 z-[-10]" />
                        <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {!!props.hasCreateEvent && !props.showRemove && (
                <div className="flex items-center gap-2">
                  <CalendarEvents
                    setFilteredEvents={setFilteredEvents}
                    originalEvents={calendarEvents}
                  />
                </div>
              )}
              {!!props.hasCreateEventAndFilters && !!props.dateTimeFilter && !props.showRemove && (
                <form className="dashboardNav--cancel py-2 flex flex-row justify-end items-center text-base">
                  <div className="mr-12">
                    {`Timezone: ${props.assetTimezone}`}
                  </div>

                  <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap">
                    <div className=" flex items-center justify-between md:justify-center">
                      <p className="mr-1">Start:</p>
                      <div className=" flex items-center justify-around w-44 h-10 relative cursor-pointer">
                        <Flatpickr
                          name={`${props.id}-start-table`}
                          placeholder="mm.dd.yyyy hh:mm"
                          className="calendar-input"
                          onChange={handleStartDateTimeChange}
                          onClose={handleCalendarClose}
                          options={{
                            defaultDate: defaultStartDate,
                            dateFormat: 'm.d.Y H:i',
                            enableTime: true,
                            maxDate: defaultEndDate,
                            disableMobile: true,
                          }}
                        />
                        <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                        <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                      </div>
                    </div>

                    <div className="flex items-center justify-between md:justify-center">
                      <p className="mr-1">End:</p>

                      <div className="flex items-center justify-around w-44 h-10 relative cursor-pointer mr-3">
                        <Flatpickr
                          placeholder="mm.dd.yyyy hh:mm"
                          className="calendar-input"
                          name={`${props.id}-end-table`}
                          onChange={handleEndDateTimeChange}
                          onClose={handleCalendarClose}
                          options={{
                            defaultDate: defaultEndDate,
                            dateFormat: 'm.d.Y H:i',
                            enableTime: true,
                            minDate: defaultStartDate,
                            disableMobile: true,
                          }}
                        />
                        <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                        <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                      </div>
                    </div>
                  </div>
                  <CSVLink
                    data={transformedData}
                    filename={`${props.assetName} ${props.id === 'chargingEvents' ? 'charging events' : 'discharging events'} ${rangeForFileName}.csv`}
                    className='csv-link'
                  >
                    <button
                      type="button"
                      className='save-as-csv rounded-md px-1 py-1 text-xs'
                      title="Save as CSV"
                    >
                      <IconContext.Provider value={{ color: '#00000' }}>
                        <PiFileCsv size={24} />
                      </IconContext.Provider>
                    </button>
                  </CSVLink>
                  <div className="filter bg-white rounded-lg ml-3 w-44">
                    {props.id === 'chargingEvents' && (
                      <input
                        type="text"
                        placeholder="Filter value"
                        className="input-filter rounded-md w-full"
                        value={chargingFilter}
                        onChange={handleChargingFilterChange}
                      />
                    )}
                    {props.id === 'dischargingEvents' && (
                      <input
                        type="text"
                        placeholder="Filter value"
                        className="input-filter rounded-md w-full"
                        value={dischargingFilter}
                        onChange={handleDischargingFilterChange}
                      />
                    )}
                  </div>
                  {!accessControl.isClientUser() ? (
                    <div className="flex items-center gap-2">
                      <button
                        className="dashboardNav--cancel p-2 add-event-button"
                        onClick={() => {
                          if (props.id === 'chargingEvents') {
                            props.toggleCreateChargeEvent();
                          } else if (props.id === 'dischargingEvents') {
                            props.toggleCreateDischargeEvent();
                          }
                        }}
                      >
                        <IconContext.Provider value={{ color: '#0F3052' }}>
                          <FiPlus />
                        </IconContext.Provider>
                      </button>
                    </div>
                  ) : null}
                </form>
              )}
              {!!props.yearFilter && (
                <form className="dashboardNav--cancel py-2 flex flex-row justify-end items-center text-base">
                  <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap">
                    <div className="flex items-center justify-between md:justify-center">
                      <p className="mr-1">Year:</p>
                      <div className="relative">
                        <select
                          className="border rounded-lg h-10 w-24"
                          value={year}
                          onChange={handleYearChange}
                          name={`${props.id}-year`}
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </span>
          </h1>
        </div>
        {
          !!showFilter && (
            <div className="grid grid-cols-2 gap-6 px-8 py-2 place-content-center">
              <div className="flex items-start">
                {queryValue !== '' && (
                  <div className="filter-pill py-2 px-5 rounded-3xl text-center text-sm flex">
                    <span>Results: {queryValue}</span>
                    <button
                      className="rounded-full bg-white ml-3 flex justify-center items-center"
                      onClick={clearFilter}
                    >
                      <IconContext.Provider value={{ color: '#0F3052' }}>
                        <FiX size={14} />
                      </IconContext.Provider>
                    </button>
                  </div>
                )}
              </div>
              <div className="filter search-filter-form bg-white rounded-lg">
                <input
                  type="text"
                  mame={`${props.id}-filter`}
                  placeholder="Filter Assets"
                  className="rounded-md mb-4 w-full py-2 px-4"
                  value={queryValue}
                  onChange={event => {
                    event.preventDefault();
                    handleChange(event);
                  }}
                />
              </div>
            </div>
          )
        }
        {
          props.child &&
          React.cloneElement(props.child, {
            ...((props.id === 'assetList' ||
              props.id === 'assetFaults' ||
              props.id === "assetsUnassigned") ? { filteredAssets } : {}),
            ...(props.id === 'VPPList' ? { filteredVpps } : {}),
            ...(props.id === 'scheduledDR' ? { filteredEvents } : {})
          })
        }
      </article >
    );
  }
);

export default Widget;
