// React
import React, { useEffect, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { CSVLink } from "react-csv";

// Services
import { fetchUpsHistoricalData as fetchUpsHistoricalDataService } from '../../services/historicalDataService';
import { fetchBackupLoadData as fetchBackupLoadDataService } from '../../services/graphService';
import { getUpsAssetData as getUpsAssetDataService } from '../../services/assetService';

// Utils
import prepLayout from '../../utils/PrepLayout';

// Constants
import DefaultAssetUpsDataLayout from '../../constants/DefaultAssetUpsDataLayout';

// Helpers
import { idToText } from '../../helpers/widget/idToText';
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import getTimezoneFromCoordinates from '../../helpers/formatDate/getTimezoneFromCoordinates';
import formatRangeForFileNameLocalTime from '../../helpers/formatDate/formatRangeForFileNameLocalTime';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { validateAndConvertDatesToUTC } from '../../helpers/formatDate/validateAndConvertDatesToUTC';
import { formatValue } from '../../helpers/icons/formatValue';
import { getGridPowerStatusColors } from '../../helpers/icons/getGridPowerStatusColors';
import { calculateAssetTimeRange, calculateDateRangeInUTC } from '../../helpers/formatDate/calculateAssetTimeRange';

// Components
import { ReactComponent as Battery } from '../../assets/battery_shape.svg';
import { ReactComponent as Thermometer } from '../../assets/thermometer_shape.svg';
import { ReactComponent as LoadPower } from '../../assets/battery-plug.svg';
import { ReactComponent as BatteryThroughput } from '../../assets/charging-time.svg';
import { ReactComponent as AverageBackupLoad } from '../../assets/average-backup-load.svg';
import { ReactComponent as BatteryDataSoh } from '../../assets/battery-data-soh.svg';
import { ReactComponent as EstimatedRuntime } from '../../assets/estimated-runtime.svg';
import { ReactComponent as GridPowerStatus } from '../../assets/grid-power-status.svg';
import { ReactComponent as RoomTemperature } from '../../assets/room-temperature.svg';
import { ReactComponent as UpsInputHz } from '../../assets/ups-input-hz.svg';
import { ReactComponent as UpsInputPower } from '../../assets/ups-input-power.svg';
import { ReactComponent as UpsInputVoltage } from '../../assets/ups-input-voltage.svg';
import BackupLoad from '../widgets/BackupLoad';
import Loader from '../Loader';
import Widget from '../widgets/Widget';

// Calendar
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';

// Icons
import { FiX, FiChevronDown } from 'react-icons/fi';
import { PiFileCsv } from "react-icons/pi";
import { IconContext } from 'react-icons';
import { BsCalendarEvent } from 'react-icons/bs';

const UpsDashboard = ({
  assetData: assetDetailsData = {},
  toggleAssetDetails,
  toggleAssetUpsDetails,
}) => {
  const { name: assetName = '' } = assetDetailsData;

  const modalRef = useRef();

  // Init responsive grid layout
  const ResponsiveGridLayout = WidthProvider(Responsive);

  // Asset difference from UTC in minutes and asset timezone name
  const assetDifferenceFromUtc = getUtcOffsetDifference(assetDetailsData?.gpsCoordinates?.latitude, assetDetailsData?.gpsCoordinates?.longitude);
  const assetTimezone = getTimezoneFromCoordinates(assetDetailsData?.gpsCoordinates?.latitude, assetDetailsData?.gpsCoordinates?.longitude);

  const now = new Date();
  const nowAssetTime = new Date(now.getTime() - (- now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
  const assetTimeEndOfToday = new Date(now.getTime() - (- now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000).setHours(23, 59, 59, 999);

  const [isBackupLoadLoading, setIsBackupLoadLoading] = useState(true);
  const [isUpsIconsDataLoading, setIsUpsIconsDataLoading] = useState(true);
  const [isAssetHistoricalDataLoading, setIsAssetHistoricalDataLoading] = useState(false);

  const [isShowExportAssetData, setIsShowExportAssetData] = useState(false);

  const [backupLoadStartDate, setBackupLoadStartDate] = useState('');
  const [backupLoadEndDate, setBackupLoadEndDate] = useState('');
  const [backupLoadAssetStartDate, setPowerAssetStartDate] = useState('');
  const [backupLoadAssetEndDate, setPowerAssetEndDate] = useState('');

  const [assetDataStartDateTime, setAssetDataStartDateTime] = useState('');
  const [assetDataEndDateTime, setAssetDataEndDateTime] = useState('');

  const [backupLoadData, setBackupLoadData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [upsIconsData, setUpsIconsData] = useState(null);

  // State to store scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Width handling
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    fetchUpsIconsData();
    fetchInitialChartsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      modalRef.current?.scrollTo(0, scrollPosition);
    }, 0);
  };

  useEffect(() => {
    scrollToPreviousPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToPreviousPosition]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to handle scroll position change
  const handleScroll = () => {
    const position = modalRef.current.scrollTop;
    setScrollPosition(position);
  };

  // Toggle show export asset data
  const toggleShowExportAssetData = () => {
    setScrollPosition(0);
    setIsShowExportAssetData(!isShowExportAssetData);
  };

  // Fetch ups icons data
  const fetchUpsIconsData = async () => {
    try {
      const upsData = await getUpsAssetDataService(assetName);
      setUpsIconsData(upsData);
    } catch (error) {
      console.error('Error fetching UPS data:', error);
    } finally {
      setIsUpsIconsDataLoading(false);
    }
  }

  // Fetch initial charts data
  const fetchInitialChartsData = async () => {
    const {
      startToUTC,
      endToUTC,
      startOfDay,
      endOfDay,
      startAssetTime,
      endAssetTime,
    } = calculateAssetTimeRange(assetDifferenceFromUtc);

    // Fetch data for Backup load
    try {
      setIsBackupLoadLoading(true);
      const backupLoadData = await fetchBackupLoadDataService(assetName, startToUTC, endToUTC);
      setBackupLoadData(backupLoadData);
    } catch (error) {
      console.error('Error fetching backup load data:', error);
    } finally {
      setBackupLoadStartDate(startOfDay);
      setBackupLoadEndDate(endOfDay);
      setPowerAssetStartDate(startAssetTime);
      setPowerAssetEndDate(endAssetTime);
      setIsBackupLoadLoading(false);
    }
  };

  // Date filter functionality for charts
  const dateFilter = async (element, startDateParam, endDateParam) => {
    if (!element || !startDateParam || !endDateParam) return;

    const {
      startToUTC,
      endToUTC,
      startOfDayUTC,
      endOfDayUTC,
    } = calculateDateRangeInUTC(startDateParam, endDateParam, assetDifferenceFromUtc);

    handleScroll();

    try {
      setIsBackupLoadLoading(true);
      const backupLoadData = await fetchBackupLoadDataService(assetName, startToUTC, endToUTC);
      setBackupLoadData(backupLoadData);
    } catch (error) {
      console.error('Error fetching backup load data:', error);
    } finally {
      setBackupLoadStartDate(startDateParam);
      setBackupLoadEndDate(endDateParam);
      setPowerAssetStartDate(startOfDayUTC);
      setPowerAssetEndDate(endOfDayUTC);
      setIsBackupLoadLoading(false);
    }
  };

  // Date filter functionality for asset historical data
  const getHistoricalData = async () => {
    const validatedDates = validateAndConvertDatesToUTC(assetDataStartDateTime, assetDataEndDateTime, assetDifferenceFromUtc);

    if (!validatedDates) {
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    try {
      setIsAssetHistoricalDataLoading(true);
      const historicalData = await fetchUpsHistoricalDataService(assetName, formattedStartDateUTC, formattedEndDateUTC);
      setAssetData(historicalData);
    } catch (error) {
      console.error('Error fetching UPS historical data:', error);
    } finally {
      setIsAssetHistoricalDataLoading(false);
    }
  };

  const rangeForFileName = formatRangeForFileNameLocalTime(new Date(assetDataStartDateTime), new Date(assetDataEndDateTime));

  const transformedData = transformDataForCSV(assetData, assetDifferenceFromUtc,
    ['BatteryThroughput', 'SOC', 'SOH', 'UPSInputVoltage', 'UPSInputHz', 'UPSInputPower',
      'BackupLoad', 'AverageBackupLoad', 'UPSEstimatedRunTime', 'UPSGridPowerStatus', 'BMSCellMaxTemperatureC',
      'BMSCellMaxTemperatureF', 'InverterAmbientTemperatureC', 'InverterAmbientTemperatureF']);

  const handleStartDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataStartDateTime(date.toString());
  };

  const handleEndDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataEndDateTime(date.toString());
  };

  const handleCalendarClose = () => {
    getHistoricalData();
  };

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(layouts, DefaultAssetUpsDataLayout, 'assetUPSDataLayout');
    }
  };

  const upsGridPowerStatus = upsIconsData?.UPSGridPowerStatus || 'N/A';
  const upsInputPower = formatValue(upsIconsData?.UPSInputPower);
  const upsInputVoltage = formatValue(upsIconsData?.UPSInputVoltage);
  const upsInputHz = formatValue(upsIconsData?.UPSInputHz);
  const backupLoad = formatValue(upsIconsData?.BackupLoad);
  const averageBackupLoad = formatValue(upsIconsData?.AverageBackupLoad);
  const batteryThroughput = formatValue(upsIconsData?.BatteryThroughput);
  const soc = formatValue(upsIconsData?.SOC);
  const soh = formatValue(upsIconsData?.SOH);
  const moduleTemperatureC = formatValue(upsIconsData?.BMSCellMaxTemperatureC, true);
  const moduleTemperatureF = formatValue(upsIconsData?.BMSCellMaxTemperatureF, true);
  const moduleTemperatureBar = moduleTemperatureC >= 50 ? 100 : Math.round(moduleTemperatureC * 2 + 15) || 'N/A';
  const roomTemperatureC = formatValue(upsIconsData?.InverterAmbientTemperatureC, true);
  const roomTemperatureF = formatValue(upsIconsData?.InverterAmbientTemperatureF, true);
  const upsEstimatedRunTime = upsIconsData?.UPSEstimatedRunTime ? upsIconsData?.UPSEstimatedRunTime : 'N/A';

  const { circle1Color, circle2Color, circle3Color } = getGridPowerStatusColors(upsGridPowerStatus);

  return (
    <div
      className='modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer'>
      <div
        ref={modalRef}
        className='container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto'>
        <header className='py-7 sticky top-0 bg-white z-50 drop-shadow'>
          <span
            className='absolute top-6 right-4'
            type='button'
            role='button'
            onClick={() => toggleAssetUpsDetails()}
          >
            <FiX size={28} />
          </span>
          <h1 className='text-2xl font-bold text-center'>
            Real-Time Data: {assetName ? assetName : "Asset"}
          </h1>
          <button
            className={`absolute top-5 right-16 ${assetDetailsData.enableBESS ? null : 'button-disabled'} rounded-md px-4 py-2 text-sm flex justify-center items-center`}
            onClick={() => {
              toggleAssetUpsDetails();
              toggleAssetDetails();
            }}
            disabled={!assetDetailsData.enableBESS}
            title={!assetDetailsData.enableBESS ? 'BESS license not active' : null}
          >
            Switch to BESS dashboard
          </button>
        </header>
        {isUpsIconsDataLoading ? (
          <div className='w-full h-full flex justify-center items-center'>
            <Loader classNames='w-32 h-32' />
          </div>
        ) : (
          <div className='p-6 flex flex-col justify-between'>
            <div className='flex flex-row justify-end'>
              {isShowExportAssetData && (
                <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap mb-4">
                  <p className="mr-2 font-medium text-lg">Export asset UPS historical data:</p>
                  <div className=" flex items-center justify-between md:justify-center">
                    <p className="mr-1">Start:</p>
                    <div className=" flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        name={`${assetName}-start`}
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        onChange={handleStartDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between md:justify-center">
                    <p className="mr-1">End:</p>
                    <div className="flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        name={`${assetName}-end`}
                        onChange={handleEndDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          minDate: assetDataStartDateTime ? new Date(assetDataStartDateTime) : null,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  {isAssetHistoricalDataLoading
                    ? <Loader classNames="w-5 h-5 mr-2 ml-1" />
                    : (<CSVLink
                      data={transformedData}
                      filename={`${assetName} UPS historical data ${rangeForFileName}.csv`}
                      className='csv-link mr-2 ml-1'
                    >
                      <button
                        type="button"
                        className='save-as-csv rounded-md px-1 py-1 text-xs'
                        title="Save as CSV"
                        disabled={!assetDataStartDateTime || !assetDataEndDateTime}
                      >
                        <IconContext.Provider value={{ color: '#00000' }}>
                          <PiFileCsv size={24} />
                        </IconContext.Provider>
                      </button>
                    </CSVLink>)
                  }
                </div>
              )}
              <FiChevronDown
                size={18}
                className={`cursor-pointer transition-transform ${isShowExportAssetData ? 'rotate-180' : null}`}
                onClick={toggleShowExportAssetData}
                title='Export asset historical data'
              />
            </div>
            <div className='col-span-1 grid grid-cols-6 gap-8 mb-8'>
              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Grid Power Status
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <GridPowerStatus
                    className='relative h-full w-full'
                    style={{ '--circle1-color': circle1Color, '--circle2-color': circle2Color, '--circle3-color': circle3Color }}
                  />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {upsGridPowerStatus}
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  UPS Input Power
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <UpsInputPower />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {upsInputPower} kW
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  UPS Input Voltage
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <UpsInputVoltage />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {upsInputVoltage} V
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  UPS Input Frequency
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <UpsInputHz />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {upsInputHz} Hz
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Backup Load
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <LoadPower />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {backupLoad} kW
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Average Backup Load <br /> (24 hours)
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <AverageBackupLoad />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {averageBackupLoad} kW
                  </p>
                </div>
              </div>
            </div>

            <div className={`col-span-1 grid grid-cols-6 gap-8 mb-8`}>
              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Battery Throughput
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <BatteryThroughput />
                  <div
                    className='absolute left-2 bottom-0 w-[100px] iconFilling'
                  />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {batteryThroughput} kWh
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Battery State of Charge
                </h2>
                <div className='relative overflow-hidden h-[125px] w-[110px]'>
                  <Battery className='relative z-20 h-full w-full' />
                  <div
                    className='absolute left-2 w-[100px] bottom-4 h-[89px]'
                  >
                    <div
                      className='absolute w-[100px] iconFilling'
                      style={{ height: `${soc}%`, bottom: 0 }}
                    >
                    </div>
                  </div>
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {soc}%
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Battery State of Health
                </h2>
                <div className='relative overflow-hidden h-[125px] w-[110px]'>
                  <BatteryDataSoh className='relative z-20 h-full w-full' />
                  <div
                    className='absolute left-2 w-[100px] bottom-4 h-[89px]'
                  >
                    <div
                      className='absolute w-[100px] iconFilling'
                      style={{ height: `${soh}%`, bottom: 0 }}
                    >
                    </div>
                  </div>
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {soh}%
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Module Temperature
                </h2>
                <div className='relative overflow-hidden h-[125px] w-[110px]'>
                  <Thermometer className='relative z-20 h-full w-full' />
                  <div
                    className='absolute left-2 bottom-0 w-[100px] iconFilling'
                    style={{ height: `${moduleTemperatureBar}%` }}
                  />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {moduleTemperatureC}&deg;C
                  </p>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {moduleTemperatureF}&deg;F
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Room Temperature
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <RoomTemperature />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {roomTemperatureC}&deg;C
                  </p>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {roomTemperatureF}&deg;F
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Estimated Backup Runtime
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <EstimatedRuntime />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {upsEstimatedRunTime}
                  </p>
                </div>
              </div>
            </div>
            <ResponsiveGridLayout
              className='layout'
              layouts={{ lg: DefaultAssetUpsDataLayout }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
              rowHeight={30}
              margin={[15, 15]}
              measureBeforeMount={true}
              useCSSTransforms={false}
              draggableHandle='.draggableHandle'
              draggableCancel='.dashboardNav--cancel'
              onLayoutChange={handleLayoutChange}
            >
              {DefaultAssetUpsDataLayout?.map(element => {
                // Make a human-readable title
                const title = idToText(element.i);

                // Inject child components dynamically
                let componentSwitch;
                let filter;
                let widgetDateFilter;
                let widgetDateRange;
                let timezone;
                let assetDifferenceFromUtcInMinutes;

                switch (element.i) {
                  case 'backupLoad':
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: backupLoadStartDate,
                      end: backupLoadEndDate,
                    };
                    componentSwitch = isBackupLoadLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <BackupLoad
                        assetName={assetName}
                        backupLoad={backupLoadData}

                        backupLoadStartDate={backupLoadAssetStartDate}
                        backupLoadEndDate={backupLoadAssetEndDate}

                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                    break;
                  default:
                    componentSwitch = null;
                    break;
                }

                return (
                  <div
                    key={element.i}
                    className='container-border grid-component__container overflow-hidden'
                  >
                    <Widget
                      title={title}
                      id={element.i}
                      hasFilter={filter}
                      dateFilter={widgetDateFilter}
                      dateRange={widgetDateRange}
                      child={componentSwitch}
                      assetTimezone={timezone}
                      assetDifferenceFromUtc={assetDifferenceFromUtcInMinutes}
                      assetName={assetName}
                      assetTimeEndOfToday={assetTimeEndOfToday}
                    />
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpsDashboard;
