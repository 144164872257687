import api from '../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

// Fetch BESS historical data for export
export const fetchBessHistoricalData = async (assetName, startDate, endDate) => {
  try {
    const response = await api.post(
      `/device/asset-data/historical`,
      {
        startDate,
        endDate,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    );

    return response.data.historicalData;
  } catch (error) {
    Notify.warning(`Failed to fetch BESS historical data`, notifyOptions);
    throw error;
  }
};

// Fetch UPS historical data for export
export const fetchUpsHistoricalData = async (assetName, startDate, endDate) => {
  try {
    const response = await api.post(
      `/device/ups-data-export`,
      {
        startDate,
        endDate,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    );

    return response.data.upsData;
  } catch (error) {
    Notify.warning(`Failed to fetch UPS historical data`, notifyOptions);
    throw error;
  }
};