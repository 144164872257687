import axios from 'axios';
import {
  isAccessTokenExpired,
  refreshingTokens,
} from '../utils/tokenRefreshing';

// For test: 'http://localhost:5000/api'
const api = axios.create({
  /**
   * @TODO: Fix issue with Cadenza dec
   * Hardcoded due to a problem with the .env environment in dev Cadenza
   * */
  baseURL: process.env.REACT_APP_BASE_URL || `https://mycdzcloud-nodeapp-dev.azurewebsites.net/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Send the access token with each request
api.interceptors.request.use(
  async config => {
    // Check if the access token has expired
    if (isAccessTokenExpired()) {
      // Refresh the access token
      await refreshingTokens();
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;
