// React
import React from 'react';
import ReactDOM from 'react-dom/client';

//Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';

// Components
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';

// Styles
import './index.css';

// DYNAMIC API BASE URL
/**
 * @TODO: Fix issue with Cadenza dec
 * Hardcoded due to a problem with the .env environment in dev Cadenza
 * */
window.$baseURL = process.env.REACT_APP_BASE_URL || 'https://mycdzcloud-nodeapp-dev.azurewebsites.net/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
