export const calculateAssetTimeRange = (assetDifferenceFromUtc) => {
  // Now user time
  const nowUserTime = new Date();

  // Helper function to calculate the start and end of day for asset time
  const calculateStartEndOfDay = (date, offset) => {
    const startOfDay = new Date(date.getTime() - (- date.getTimezoneOffset() - offset) * 60000);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date.getTime() - (- date.getTimezoneOffset() - offset) * 60000);
    endOfDay.setHours(23, 59, 59, 999);
    return { startOfDay, endOfDay };
  };

  const { startOfDay, endOfDay } = calculateStartEndOfDay(nowUserTime, assetDifferenceFromUtc);

  // Difference between daylight savings time and standard time
  const dstOffsetDifference = endOfDay.getTimezoneOffset() !== startOfDay.getTimezoneOffset()
    ? (endOfDay.getTimezoneOffset() - startOfDay.getTimezoneOffset())
    : 0;

  // Apply the asset's time difference from UTC
  const startAssetTime = new Date(startOfDay.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc) * 60000);
  const endAssetTime = new Date(endOfDay.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc + dstOffsetDifference) * 60000);

  // Format the start and end dates as ISO 8601 strings
  const startToUTC = startAssetTime.toISOString();
  const endToUTC = endAssetTime.toISOString();

  return { startToUTC, endToUTC, startOfDay, endOfDay, startAssetTime, endAssetTime };
}

export const calculateDateRangeInUTC = (startDateParam, endDateParam, assetDifferenceFromUtc) => {
  // Ensure startDate and endDate are Date objects
  let startDate = startDateParam instanceof Date ? startDateParam : new Date(startDateParam);
  let endDate = endDateParam instanceof Date ? endDateParam : new Date(endDateParam);

  // Validate dates
  if (!startDate || !endDate) return;

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error("Invalid Date objects");
    return;
  }

  // Extract day, month, and year from selected dates
  const localStartDay = startDate.getDate();
  const localStartMonth = startDate.getMonth();
  const localStartYear = startDate.getFullYear();

  const localEndDay = endDate.getDate();
  const localEndMonth = endDate.getMonth();
  const localEndYear = endDate.getFullYear();

  // Create new date objects using the extracted components
  const startOfDayUTC = new Date(Date.UTC(localStartYear, localStartMonth, localStartDay, 0, 0, 0, 0));
  const endOfDayUTC = new Date(Date.UTC(localEndYear, localEndMonth, localEndDay, 23, 59, 59, 999));

  // Adjust for the asset's timezone offset
  startOfDayUTC.setMinutes(startOfDayUTC.getMinutes() - assetDifferenceFromUtc);
  endOfDayUTC.setMinutes(endOfDayUTC.getMinutes() - assetDifferenceFromUtc);

  // Calculate difference between daylight savings time and standard time
  const dstOffsetDifference = endOfDayUTC.getTimezoneOffset() !== startOfDayUTC.getTimezoneOffset()
    ? (endOfDayUTC.getTimezoneOffset() - startOfDayUTC.getTimezoneOffset())
    : 0;

  // Adjust endOfDayUTC with dstOffsetDifference
  endOfDayUTC.setMinutes(endOfDayUTC.getMinutes() - dstOffsetDifference);

  // Format the start and end dates as ISO 8601 strings
  const startToUTC = startOfDayUTC.toISOString();
  const endToUTC = endOfDayUTC.toISOString();

  return { startToUTC, endToUTC, startOfDayUTC, endOfDayUTC };
}

export const calculateTableTimeRanges = (assetDifferenceFromUtc) => {
  const nowUserTime = new Date();

  // Start and end of day for asset time
  const startOfDay = new Date(nowUserTime.getTime() - (- nowUserTime.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(nowUserTime.getTime() - (- nowUserTime.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
  endOfDay.setHours(23, 59, 59, 999);

  // Calculate the end of the next 7 days for asset time
  const endOfNext7Days = new Date(endOfDay.getTime());
  endOfNext7Days.setDate(endOfNext7Days.getDate() + 7);
  endOfNext7Days.setHours(23, 59, 59, 999);

  // Difference between daylight savings time and standard time
  const dstOffsetDifference = endOfNext7Days.getTimezoneOffset() !== startOfDay.getTimezoneOffset()
    ? (endOfNext7Days.getTimezoneOffset() - startOfDay.getTimezoneOffset())
    : 0;

  // Apply the asset's time difference from UTC
  const startAssetTimeInUTC = new Date(startOfDay.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc) * 60000);
  const endAssetTimeInUTC = new Date(endOfNext7Days.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc + dstOffsetDifference) * 60000);

  // Format the start and end dates as ISO 8601 strings
  const startDate = startAssetTimeInUTC.toISOString();
  const endDate = endAssetTimeInUTC.toISOString();

  return { startDate, endDate, startOfDay, endOfNext7Days };
};